import * as React from 'react';
import { Link } from 'gatsby';
import { Layout, Seo } from '../components';
import styled from 'styled-components';

const NotFoundPage = () => {
   return (
      <Layout>
         <Seo title='404' />
         <NotFoundWrap className='container'>
            <h1>Ooops</h1>
            <p>Page doesn't exist</p>
            <Link to='/'>
               <button className='btn'>Back to Home</button>
            </Link>
         </NotFoundWrap>
      </Layout>
   );
};

export default NotFoundPage;

const NotFoundWrap = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 80vh;
`;
